.asScrollable.is-enabled {
  overflow: hidden !important;
}
.is-enabled .asScrollable-container {
  overflow: hidden !important;
  -webkit-box-sizing: content-box !important;
     -moz-box-sizing: content-box !important;
          box-sizing: content-box !important;
}
.is-enabled .asScrollable-container::-webkit-scrollbar {
  width: 0;
  height: 0;
  -webkit-appearance: none;
}
.asScrollable-vertical.is-enabled .asScrollable-container {
  overflow-y: scroll !important;
}
.asScrollable-horizontal.is-enabled .asScrollable-container {
  overflow-x: scroll !important;
}
.is-enabled .asScrollable-content {
  position: relative !important;
  overflow: visible !important;
}
.is-enabled .asScrollable-content:before,
.is-enabled .asScrollable-content:after {
  content: " ";
  display: table;
}
.is-enabled .asScrollable-content:after {
  clear: both;
}
.asScrollable-bar {
  -webkit-transition: opacity 0.5s;
       -o-transition: opacity 0.5s;
          transition: opacity 0.5s;
  position: absolute;
  
  
  bottom: 0;
  right: 0;
  line-height: 0;
  overflow: hidden;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  user-focus: ignore;
  user-input: disabled;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 10;
}

.comments .asScrollable-bar{
    height:100%;
    top:0;
}
.dropdown-notifications .asScrollable-bar{
    height:100%;
    top:0;
}
.is-disabled .asScrollable-bar {
  display: none;
}
.asScrollable-bar-hide {
  -webkit-transition-delay: 400ms;
       -o-transition-delay: 400ms;
          transition-delay: 400ms;
  opacity: 0;
}
.asScrollable-bar.is-hovering {
  background: rgba(238, 238, 238, 0.2);
}
.asScrollable-bar.is-dragging {
  background: rgba(238, 238, 238, 0.6) !important;
  opacity: 1;
}
.asScrollable-bar.is-disabled {
  display: none;
}
.asScrollable-bar-handle {
  -webkit-transition: width,height 0.5s;
       -o-transition: width,height 0.5s;
          transition: width,height 0.5s;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 0;
      background: rgba(243,247,249,.5);
  cursor: pointer;
  border-radius: 2px;
}

.comments .asScrollable-bar-handle {
  background: #c2cbcf;
  border-radius: 0;
  left:3px;
}
.dropdown-notifications .asScrollable-bar-handle {
  background: #c2cbcf;
  border-radius: 0;
  left:3px;
}
.asScrollable-bar.is-dragging .asScrollable-bar-handle {
  background: rgba(150, 150, 150, 0.8) !important;
}
.asScrollable-bar.is-dragging,
.asScrollable-bar.is-hovering {
  border-radius: 5px;
}
.comments .asScrollable-bar.is-dragging,
.comments .asScrollable-bar.is-hovering {
  border-radius: 5px;
}
.dropdown-notifications .asScrollable-bar.is-dragging,
.dropdown-notifications .asScrollable-bar.is-hovering {
  border-radius: 5px;
}
.asScrollable-bar.is-dragging .asScrollable-bar-handle,
.asScrollable-bar.is-hovering .asScrollable-bar-handle {
  border-radius: 5px;
}
.comments .asScrollable-bar.is-dragging .asScrollable-bar-handle,
.comments .asScrollable-bar.is-hovering .asScrollable-bar-handle {
  border-radius: 0;
}
.dropdown-notifications .asScrollable-bar.is-dragging .asScrollable-bar-handle,
.dropdown-notifications .asScrollable-bar.is-hovering .asScrollable-bar-handle {
  border-radius: 0;
}
.asScrollable-bar-vertical {
  width: 4px;
  margin: 5px 3px;
  height: 100%;
  height: -webkit-calc(100% - 2*5px);
  height: calc(100% - 2*5px);
}

.comments .asScrollable-bar-vertical{
    width:11px;
    background-color: #fff;
    border-radius: 0;
    box-shadow: 2px 0 3px rgba(0, 0, 0, 0.08) inset;
    margin: 0;
    height:100%;
}

.dropdown-notifications .asScrollable-bar-vertical{
    width:16px;
    background-color: #fff;
    border-radius: 0 5px 5px 0;
    box-shadow: 2px 0 3px rgba(0, 0, 0, 0.08) inset;
    margin: 0;
    height:100%;
}

.asScrollable-bar-vertical.is-dragging,
.asScrollable-bar-vertical.is-hovering {


}
.asScrollable-bar-vertical .asScrollable-bar-handle {
  width: 100%;
}
.comments .asScrollable-bar-vertical .asScrollable-bar-handle {
  width: 6px;
}
.dropdown-notifications .asScrollable-bar-vertical .asScrollable-bar-handle {
  width: 11px;
}
.asScrollable-bar-horizontal {
  height: 4px;
  margin: 3px 5px;
  width: 100%;
  width: -webkit-calc(100% - 2*5px);
  width: calc(100% - 2*5px);
}
.asScrollable-bar-horizontal.is-dragging,
.asScrollable-bar-horizontal.is-hovering {
  height: 10px;
  margin: 1px 5px;
}
.asScrollable-bar-horizontal .asScrollable-bar-handle {
  height: 100%;
}
.asScrollable.is-scrolling .asScrollable-bar {
  -webkit-transition: opacity 0;
       -o-transition: opacity 0;
          transition: opacity 0;
  opacity: 1;
}
.asScrollable.is-hovering .asScrollable-bar-handle {
      background: rgba(243,247,249,.5);
}

.comments .asScrollable.is-hovering .asScrollable-bar-handle {
  background: #c2cbcf;
}
.dropdown-notifications .asScrollable.is-hovering .asScrollable-bar-handle {
  background: #c2cbcf;
}
.asScrollable.is-dragging {
  user-focus: ignore;
  user-input: disabled;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
