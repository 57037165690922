/************************* */
/* 1. BASE  test*/
/************************* */
body {
  padding: 0.0;
  margin: 0;
  height: 100%;
  font-family: 'helveticaneue';
  font-weight: 300;
  font-size: 16px;
  color: #3d3d3d;
  line-height: 28px;
  overflow-x: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body.colored {
  background-color: #eef7f8;
}
a,
button {
  outline: medium none !important;
  text-decoration: none !important;
}
b,
strong {
  font-weight: 700;
}
p {
  margin: 0;
  line-height: 25px;
  font-size: 16px;
}
ul {
  margin: 0;
  padding: 0;
}
input,
select,
textarea,
select {
  outline: none;
}
ul,
li,
ol {
  list-style-type: none;
}
img {
  height: auto;
  max-width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'helveticaneue';
  font-weight: 300;
  color: #1b3e59;
  margin: 0;
}
h1 {
  font-size: 26px;
  line-height: 32px;
}
::selection {
  color: #fff;
  background-color: #255378;
}
::-moz-selection {
  color: #fff;
  background-color: #255378;
}
.container {
  max-width: 1230px;
  width: inherit !important;
}
.col-centered {
  float: none;
  margin: 0 auto;
}
.btn {
  font-size: 19px;
  font-weight: 300;
  letter-spacing: normal;
  
  background-color: transparent;
  color: #ff9600;
  
  border-radius: 13px;
  border: 2px solid #ff9600;
  display: inline-block;
  padding: 5px 25px;
  min-width: 152px;
  margin: 0;
  line-height: 35px;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}
.btn:hover,
.btn:focus {
  border-color: transparent;
  background: transparent;
  background-color: #ff9600;
  color:#fff;
}
.btn.white {
  background-color: transparent;
  color: #ff9600;
  border-color: #ff9600;
}
.btn.white:hover {
  color: #fff;
  background-color: #ff9600;
  border-color: transparent;
}
.no-padding {
  padding: 0 !important;
}
.no-margin {
  margin: 0;
}
.margin-top20 {
  margin-top: 20px !important;
}
.row-same-height {
  display: table;
  height: 100%;
  table-layout: fixed;
  width: 100%;
}
.col-full-height {
  display: table-cell;
  float: none;
  height: 100%;
  vertical-align: top;
}
.center {
  display: table;
  margin: 0 auto;
}
.fontello-icon {
  display: inline-block;
  font-family: "fontello";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  text-decoration: inherit;
  text-transform: none;
}
.field-row input,
.field-row textarea {
  height: 50px;
  border: 1px solid #979797;
  border-radius: 5px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3) inset;
  width: 100%;
  color: rgba(37, 83, 120, 0.5);
  font-size: 20px;
  padding: 0 15px;
}
.field-row input:focus,
.field-row textarea:focus {
  color: #255378;
}
.field-row textarea {
  resize: none;
  height: 120px;
  padding-top: 8px;
}
.field-row ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: rgba(37, 83, 120, 0.5);
}
.field-row :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: rgba(37, 83, 120, 0.5);
  opacity: 1;
}
.field-row ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: rgba(37, 83, 120, 0.5);
  opacity: 1;
}
.field-row :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(37, 83, 120, 0.5);
}
.field-row label {
  color: #255378;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
}
/***************** */
/* 2. LOADER */
/***************** */
#loader-container {
  position: fixed;
  display: table;
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  vertical-align: middle;
  background: #fff;
  z-index: 99999;
}
#loader-container .loader-content {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  z-index: 3;
  text-align: center;
}
#loader-container .loader-content .loader {
  position: relative;
  display: table;
  vertical-align: middle;
  z-index: 3;
  text-align: center;
  margin: 0 auto;
}
/************************* */
/* Login page  */
/************************* */
.login .login-content:not(.register) {
  background: url('../images/login.jpg') no-repeat 0 0;
  background-size: cover;
  padding: 0 0 30px;
}
.login .login-content.register {
  background-color:#eef7f8;
  padding:30px;
}
.login .login-content h1 {
  font-family: Lato;
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  color: #fff;
  padding: 110px 0 110px;
  text-align: center;
}
.login .login-content .login-form {
  width: 420px;
  padding-bottom: 40px;
  background-color: white;
  border: 1px solid #d3e9ea;
  border-radius: 10px;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.07);
  margin: 0 auto;
}
.login .login-content .login-form h2 {
  color: #255378;
  font-size: 22px;
  font-weight: 300;
  line-height: 27px;
  text-align: center;
  padding: 30px 0;
  border-bottom: 1px solid #9acbce;
  margin-bottom: 45px;
}
.login .login-content.register .login-form h2 {
    font-size: 15px !important;
}
.login .login-content .login-form .field-row {
  margin-bottom: 20px;
  padding: 0 30px;
}
.login .login-content .login-form .field-row input {
  width: 100%;
  height: 60px;
  border: 1px solid #9acbce;
  border-radius: 6px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
  color: #899baa;
  font-size: 22px;
  padding: 0 20px;
}
.login .login-content .login-form .field-row ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #899baa;
}
.login .login-content .login-form .field-row :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #899baa;
  opacity: 1;
}
.login .login-content .login-form .field-row ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #899baa;
  opacity: 1;
}
.login .login-content .login-form .field-row :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #899baa;
}
.login .login-content .login-form .field-row .forgotten-pass {
  float: right;
  color: #255378;
  line-height: 18px;
}
.login .login-content .login-form .field-row .forgotten-pass:hover {
  text-decoration: underline !important;
}
.login .login-content:not(.register) .login-form .btn {
  display: block;
  margin: 110px auto 0;
}
.login .login-content.register .login-form .btn {
  display: block;
  margin: 30px auto 0;
}
.check-content {
  margin-top: 15px;
}
.check-content input[type="checkbox"] {
  display: none;
}
.check-content input[type="checkbox"]:checked + label:after {
  font-family: 'fontello';
  background-color: #7e77ff;
  content: '\e807';
  color: #fff;
  font-size: 8px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
}
.check-content label {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  color: #899baa;
  font-weight: 300;
  line-height: 18px;
  position: relative;
  padding-left: 27px;
  float: left;
}
.check-content label:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: 1px solid #7e77ff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.2);
}
.check-content label a {
  color: #bc9859;
  text-decoration: underline !important;
}
/************************* */
/* Header and left side nav  */
/************************* */
#header {
  width: 100%;
  height: 70px;
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  border-bottom: 1px solid #d7d7d7;
  /*.left-top-nav {
        float: left;

        li {
            float: left;
            margin: 0 22px;
            height: 70px;
            line-height: 70px;

            a {
                color: #255378;
                font-size: 12px;
                font-weight: 400;
                text-transform: uppercase;

                span {
                    opacity: 0.6;
                    -moz-transition: 0.3s all ease;
                    -o-transition: 0.3s all ease;
                    -webkit-transition: 0.3s all ease;
                    transition: 0.3s all ease;
                }

                &:hover, &.active {
                    span {
                        opacity: 1;
                    }
                }

                i {
                    color: #ff9600;
                    font-size: 20px;
                    vertical-align: middle;
                    padding-right: 10px;
                    margin-top: -4px;

                    &.bold {
                        font-weight: 700;
                    }
                }
            }
        }
    }*/
}
#header .logo {
  width: 240px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.14) inset;
  float: left;
  padding-top: 2px;
  background: url("../images/logo-bcg.png") no-repeat 0 0;
}
#header .logo a {
  color: #fff;
  font-family: Lato;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
}
#header .nav-toggle {
  float: left;
}
#header .nav-toggle li {
  float: left;
  padding: 23px;
  position: relative;
}
#header .nav-toggle li .menu-trigger {
  position: relative;
  height: 23px;
  width: 24px;
  display: block;
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}
#header .nav-toggle li .menu-trigger span {
  background-color: #8498a9;
  display: block;
  width: 100%;
  height: 4px;
  left: 0;
  position: absolute;
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -moz-transition: all 0.25s ease-in-out 0s;
  -o-transition: all 0.25s ease-in-out 0s;
  -webkit-transition: all 0.25s ease-in-out 0s;
  transition: all 0.25s ease-in-out 0s;
}
#header .nav-toggle li .menu-trigger span:nth-child(1) {
  top: 0;
}
#header .nav-toggle li .menu-trigger span:nth-child(2),
#header .nav-toggle li .menu-trigger span:nth-child(3) {
  top: 9px;
}
#header .nav-toggle li .menu-trigger span:nth-child(4) {
  top: 18px;
}
#header .nav-toggle li .menu-trigger.open span {
  background-color: #00AEE7;
  /*&:nth-child(1), &:nth-child(4) {
                            opacity: 0;
                        }

                        &:nth-child(2) {
                            -moz-transform: rotate(45deg);
                            -ms-transform: rotate(45deg);
                            -o-transform: rotate(45deg);
                            -webkit-transform: rotate(45deg);
                            transform: rotate(45deg);
                        }

                        &:nth-child(3) {
                            -moz-transform: rotate(-45deg);
                            -ms-transform: rotate(-45deg);
                            -o-transform: rotate(-45deg);
                            -webkit-transform: rotate(-45deg);
                            transform: rotate(-45deg);
                        }*/
}
#header .nav-toggle li .menu-trigger:hover span {
  background-color: #00AEE7;
}
#header .right-top-nav {
  float: right;
  display: table;
  height: 70px;
}
#header .right-top-nav li {
  display: table-cell;
  vertical-align: middle;
  padding: 0 20px;
  position: relative;
}
#header .right-top-nav li .notifications-alert i {
  position: relative;
  font-size: 20px;
  color: #255378;
  margin-top: 10px;
}
#header .right-top-nav li .notifications-alert i span {
  font-family: "helveticaneue";
  width: 18px;
  height: 18px;
  background-color: #ffc066;
  position: absolute;
  top: -8px;
  right: -8px;
  color: #255378;
  font-size: 11px;
  border-radius: 50%;
  display: block;
  text-align: center;
  line-height: 18px;
}
#header .right-top-nav li .dropdown-notifications {
  position: fixed;
  display: inherit;
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  left: auto;
  top: 80px;
  right: 20px;
  width: 400px;
  -moz-transition: 0.3s all ease-out;
  -o-transition: 0.3s all ease-out;
  -webkit-transition: 0.3s all ease-out;
  transition: 0.3s all ease-out;
  padding: 0;
  background-color: white;
  border: 1px solid #d3e9ea;
  border-radius: 5px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
}
#header .right-top-nav li .dropdown-notifications:before {
  border-bottom: 12px solid #d3e9ea;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  content: "";
  height: 0;
  position: absolute;
  right: 68px;
  top: -12px;
  width: 0;
}
#header .right-top-nav li .dropdown-notifications:after {
  border-bottom: 10px solid #fff;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  content: "";
  height: 0;
  position: absolute;
  right: 70px;
  top: -10px;
  width: 0;
}
#header .right-top-nav li .dropdown-notifications .list-group {
  padding: 15px 26px 15px 15px;
  max-height: 470px;
}
#header .right-top-nav li .dropdown-notifications .list-group .list-group-item {
  border-color: #c4dddd;
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.07);
  white-space: normal;
  margin-bottom: 15px;
  padding: 15px 15px 8px 15px;
}
#header .right-top-nav li .dropdown-notifications .list-group .list-group-item:last-child {
  margin-bottom: 0;
}
#header .right-top-nav li .dropdown-notifications .list-group .list-group-item:hover {
  background: #fff;
  cursor: default;
}
#header .right-top-nav li .dropdown-notifications .list-group .list-group-item .close {
  background: #fff;
  position: absolute;
  right: -7px;
  top: -7px;
  font-size: 14px;
  opacity: 1;
  color: #c4dddd;
}
#header .right-top-nav li .dropdown-notifications .list-group .list-group-item .close:hover {
  color: #255378;
}
#header .right-top-nav li .dropdown-notifications .list-group .list-group-item .media {
  margin: 0;
}
#header .right-top-nav li .dropdown-notifications .list-group .list-group-item .media .profile {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-right: 15px;
}
#header .right-top-nav li .dropdown-notifications .list-group .list-group-item .media .media-body .media-heading {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #255378;
  min-height: 30px;
  position: relative;
  padding-right: 20px;
}
#header .right-top-nav li .dropdown-notifications .list-group .list-group-item .media .media-body .media-heading i {
  font-size: 20px;
  position: absolute;
  right: 0;
  top: 0;
  color: #00c0ff;
}
#header .right-top-nav li .dropdown-notifications .list-group .list-group-item .media .media-body p {
  color: #00c0ff;
  font-size: 15px;
  font-style: italic;
  line-height: 16px;
}
#header .right-top-nav li .dropdown-notifications .list-group .list-group-item .media .media-body .date {
  color: #98abb4;
  font-size: 12px;
  font-weight: 300;
  line-height: 12px;
}
#header .right-top-nav li.account {
  padding-left: 10px;
}
#header .right-top-nav li.account a img {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
}
#header .right-top-nav li.open .dropdown-notifications {
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  display: inherit;
}
#headerShare {
  padding:10px;
  background-color: #172e42;
}
@keyframes dropdownOpen {
  0% {
    opacity: 0;
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes dropdownOpen {
  0% {
    opacity: 0;
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 1;
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.left-side-nav {
  height: 100%;
  max-height: 100%;
  padding-top: 70px;
  position: fixed;
  top: 0;
  left: 0;
  width: 240px;
  background-color: #172e42;
  z-index: 9999;
  overflow: hidden;
  -moz-transition: all 150ms ease-out;
  -o-transition: all 150ms ease-out;
  -webkit-transition: all 150ms ease-out;
  transition: all 150ms ease-out;
}
.left-side-nav.push {
  left: -240px;
}
.left-side-nav .side-nav-title {
  display: block;
  padding: 20px 30px;
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;
  background: #1c3e5a;
}
.left-side-nav .side-nav-title i {
  color: #00c0ff;
  font-size: 20px;
  padding-right: 15px;
}
.left-side-nav .search {
  width: 100%;
  position: relative;
  padding: 15px 12px;
  border-top: 1px solid #516271;
}
.left-side-nav .search:after {
  font-family: 'fontello';
  content: '\e80d';
  color: #97adbf;
  font-size: 17px;
  position: absolute;
  right: 22px;
  top: 20px;
}
.left-side-nav .search input {
  height: 40px;
  line-height: 30px;
  width: 100%;
  border: 1px solid #d3e9ea;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  color: #97adbf;
  font-size: 18px;
  padding: 0 25px 0 10px;
}
.left-side-nav .search ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #97adbf;
}
.left-side-nav .search :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #97adbf;
  opacity: 1;
}
.left-side-nav .search ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #97adbf;
  opacity: 1;
}
.left-side-nav .search :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #97adbf;
}
.left-side-nav .quick-links {
  padding: 15px 0;
  min-height: 62px;
}
.left-side-nav .quick-links li {
  width: 33.33%;
  float: left;
  height: 30px;
  text-align: center;
  border-right: 2px solid #35495a;
}
.left-side-nav .quick-links li:last-child {
  border: 0;
}
.left-side-nav .quick-links li a {
  font-size: 24px;
  color: #a2abb3;
  -moz-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  -webkit-transition: 0.25s all ease;
  transition: 0.25s all ease;
}
.left-side-nav .quick-links li a:hover {
  color: #fff;
}
.left-side-nav .quick-links li a.icon-1 {
  font-size: 22px;
}
.left-side-nav .quick-links li a.icon-2 {
  font-size: 24px;
}
.left-side-nav .quick-links li a.icon-3 {
  font-size: 23px;
}
.left-side-nav .quick-links li.active a {
  color: #fff;
}
.left-side-nav .cd-accordion-menu {
  width: 100%;
  background: #fff;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
.left-side-nav .cd-accordion-menu input[type=checkbox]:checked + label {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  position: relative;
  border: 0;
}
.left-side-nav .cd-accordion-menu input[type=checkbox]:checked + label span {
  color: #fff;
}
.left-side-nav .cd-accordion-menu input[type=checkbox]:checked + label i {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.left-side-nav .cd-accordion-menu li {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.left-side-nav .cd-accordion-menu li i {
  float: right;
  color: #fff;
  font-size: 12px;
  vertical-align: middle;
  margin: 6px 0;
  -moz-transition: all 200ms linear 0s;
  -o-transition: all 200ms linear 0s;
  -webkit-transition: all 200ms linear 0s;
  transition: all 200ms linear 0s;
}
.left-side-nav .cd-accordion-menu li ul {
  display: none;
  position: relative;
  z-index: 1;
}
.left-side-nav .cd-accordion-menu li ul li {
  background: #255378;
}
.left-side-nav .cd-accordion-menu li ul li > label {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 10px 12px 10px 25px;
  background: #446988;
  color: #fff;
  border-bottom: 1px solid #53738e;
}
.left-side-nav .cd-accordion-menu li ul li ul li a {
  padding: 10px 12px 10px 25px;
  color: #9aaab7;
  border-bottom: 1px solid #486883;
  background-color: #34546f;
  font-weight: 300;
  transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
}
.left-side-nav .cd-accordion-menu li ul li ul li a span {
  opacity: 0.6;
  padding-right: 10px;
  font-weight: 400;
  color: #fff;
  transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
}
.left-side-nav .cd-accordion-menu li ul li ul li a:hover {
  color: #fff;
}
.left-side-nav .cd-accordion-menu li ul li ul li a:hover span {
  opacity: 1;
}
.left-side-nav .cd-accordion-menu li label,
.left-side-nav .cd-accordion-menu li a {
  font-size: 16px;
  font-weight: 300;
  display: block;
  margin: 0;
  border-bottom: 1px solid #152a3c;
  cursor: pointer;
}
.left-side-nav .cd-accordion-menu li > label {
  color: #fff;
  padding: 12px 12px 12px 15px;
  background-color: #547fa3;
  z-index: 2;
}
.left-side-nav .cd-accordion-menu li > label span {
  color: #bbccda;
  padding-right: 8px;
  font-weight: 400;
}
.left-side-nav .cd-accordion-menu input[type=checkbox] {
  position: absolute;
  opacity: 0;
}
.page-content {
  margin: 70px 0 0 240px;
  position: relative;
  -moz-transition: all 150ms ease-out;
  -o-transition: all 150ms ease-out;
  -webkit-transition: all 150ms ease-out;
  transition: all 150ms ease-out;
}
.page-content.push {
  margin-left: 0;
}
/************************* */
/* Index page  */
/************************* */
.plans-list {
  padding: 0 5px 30px;
}
.plans-list h1 {
  margin: 20px 0 0;
}
.plans-list h2 {
  color: #1b3e59;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  opacity: 0.6;
  margin: 15px 0 10px;
}
.plans-list [class^="col-"],
.plans-list [class^=" col-"] {
  padding-right: 10px;
  padding-left: 10px;
}
.plans-list .row {
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 18px;
}
.plans-list .plans-list-content {
  width: 100%;
  background-color: white;
  border: 1px solid #d3e9ea;
  border-radius: 5px;
  padding: 10px 10px 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.07);
}
.plans-list .plans-list-content .image-content {
  border-radius: 2px;
  background-color: white;
  border: 1px solid #7d98ae;
  position: relative;
  display: block;
  text-align: center;
}
.plans-list .plans-list-content .image-content .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(27, 62, 89, 0.5);
  opacity: 0;
  -moz-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.plans-list .plans-list-content .image-content .overlay i {
  color: #fff;
  font-size: 30px;
  top: 50%;
  left: 50%;
  position: absolute;
  height: 26px;
  width: 45px;
  margin: -15px 0 0 -22px;
}
.plans-list .plans-list-content .image-content:hover .overlay {
  opacity: 1;
}
.plans-list .plans-list-content .plans-list-info {
  margin-top: 8px;
}
.plans-list .plans-list-content .plans-list-info li {
  float: left;
  display: table;
  width: 100%;
}
.plans-list .plans-list-content .plans-list-info li span {
  display: block;
}
.plans-list .plans-list-content .plans-list-info li .title {
  display: table-cell;
  vertical-align: middle;
  color: #7c98ae;
  font-size: 15px;
  font-weight: 400;
  line-height: 16px;
  text-transform: uppercase;
  padding: 6px 0;
}
.plans-list .plans-list-content .plans-list-info li .name {
  display: table-cell;
  vertical-align: middle;
  color: #255378;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  text-align: right;
}
.plans-list .plans-list-content .plans-list-info li .date {
  opacity: 0.8;
  color: #255378;
  font-size: 18px;
  line-height: 24px;
  float: right;
  text-align: right;
}
/************************* */
/* People page  */
/************************* */
.people-list {
  padding: 15px 10px 30px;
}
.people-list [class^="col-"],
.people-list [class^=" col-"] {
  padding-right: 10px;
  padding-left: 10px;
}
.people-list .row {
  margin-right: -10px;
  margin-left: -10px;
}
.people-list .people-list-content {
  background: #fff;
  border: 1px solid #d3e9ea;
  border-radius: 5px;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.07);
  overflow: hidden;
  margin: 10px 0;
}
.people-list .people-list-content .heading {
  height: 70px;
  background-color: #f6f8fa;
  padding: 6px 10px 10px;
}
.people-list .people-list-content .heading .control {
  float: right;
}
.people-list .people-list-content .heading .control a {
  color: #aab8c2;
  float: left;
  font-size: 18px;
  margin: 0 4px;
}
.people-list .people-list-content .heading .control a:hover,
.people-list .people-list-content .heading .control a.active {
  color: #255378;
}
.people-list .people-list-content .profile {
  width: 110px;
  height: 110px;
  background-color: #f6f8fa;
  border-radius: 50%;
  padding: 5px;
  margin: -55px auto 0 auto;
}
.people-list .people-list-content .profile img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.people-list .people-list-content .info-list {
  padding: 7px 15px 8px;
  border-bottom: 1px solid #d3e9ea;
}
.people-list .people-list-content .info-list li .title {
  color: #7c98ae;
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  width: 50%;
  display: inline-block;
  float: left;
  padding: 5px 0;
}
.people-list .people-list-content .info-list li .info {
  line-height: normal;
  margin: 2px auto;
  max-width: 240px;
}
.people-list .people-list-content .info-list li .info textarea,
.people-list .people-list-content .info-list li .info textarea[disabled] {
  display: block;
  width: 100%;
  text-align: center;
  color: #1b3e59;
  -webkit-text-fill-color: #1b3e59;
  font-size: 18px;
  border: 0;
  border-bottom: 1px solid transparent;
  background: #fff;
  overflow: hidden;
  resize: none;
  max-height: 160px;
  height: 23px;
  overflow-x: hidden;
  padding: 0;
  line-height: normal;
}
.people-list .people-list-content .info-list li .info.main-info textarea,
.people-list .people-list-content .info-list li .info.main-info textarea[disabled] {
  color: #255378;
  -webkit-text-fill-color: #255378;
}
.people-list .people-list-content .info-list li .info.name textarea,
.people-list .people-list-content .info-list li .info.name textarea[disabled] {
  font-weight: 500;
}
.people-list .people-list-content .info-list.active .info textarea {
  border-color: #e5ecf1;
}
.people-list .people-list-content .info-list.insert li .info textarea,
.people-list .people-list-content .info-list.insert li .info textarea[disabled] {
  color: #255378;
  opacity: 0.6;
}
.people-list .people-list-content .info-list.insert li .info ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #255378;
}
.people-list .people-list-content .info-list.insert li .info :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #255378;
  opacity: 1;
}
.people-list .people-list-content .info-list.insert li .info ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #255378;
  opacity: 1;
}
.people-list .people-list-content .info-list.insert li .info :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #255378;
}
.people-list .people-list-content .check-info {
  padding: 12px 15px 5px;
  margin-top: 2px;
}
.people-list .people-list-content .check-info .switch {
  position: relative;
  margin-bottom: 11px;
  padding-right: 40px;
}
.people-list .people-list-content .check-info .switch span {
  display: block;
  color: #517593;
  font-size: 15px;
  font-weight: 300;
  line-height: 17px;
}
.people-list .people-list-content .check-info .switch .check {
  position: absolute;
  right: 0;
  top: 0;
}
.people-list .people-list-content .check-info .switch .check label {
  margin: 0;
}
.switch input {
  display: none;
}
.switch input:checked + label {
  background-color: #255378;
}
.switch input:checked + label:after {
  margin-left: 19px;
}
.switch label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 40px;
  height: 20px;
  background-color: #BFCCD6;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
  -webkit-transition: background 0.15s;
  -moz-transition: background 0.15s;
  -o-transition: background 0.15s;
  transition: background 0.15s;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
}
.switch label:after,
.switch label:before {
  display: block;
  position: absolute;
  content: "";
}
.switch label:after {
  background-color: #fff;
  border-radius: 50%;
  height: 16px;
  left: 3px;
  top: 2px;
  -moz-transition: 0.15s all ease;
  -o-transition: 0.15s all ease;
  -webkit-transition: 0.15s all ease;
  transition: 0.15s all ease;
  width: 16px;
}
/************************* */
/* Project page  */
/************************* */
.box {
  width: 100%;
  background-color: #fff;
  border: 1px solid #d3e9ea;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.07);
  margin-bottom: 20px;
}
.project {
  padding: 15px 0 0;
}
.project h2 {
  float: left;
  color: #7c98ae;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
.project .right-side {
  padding-left: 0;
}
.project .project-version {
  height: 52px;
  background-color: #fff;
  border: 1px solid #d3e9ea;
  border-radius: 5px;
  margin-bottom: 20px;
  position: relative;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.project .project-version.active {
  border-radius: 5px 5px 0 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}
.project .project-version.active .dropdown {
  opacity: 1;
  max-height: 1400px;
  pointer-events: auto;
}
.project .project-version .title {
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  display: block;
  float: left;
  min-width: 210px;
  color: #7c98ae;
  padding: 16px 20px;
  border-right: 1px solid #d3e9ea;
}
.project .project-version .version-list {
  float: left;
  margin-left: 15px;
}
.project .project-version .version-list li {
  margin-right: 16px;
  float: left;
}
.project .project-version .version-list li .add {
  font-size: 12px;
  height: 30px;
  line-height: 28px;
  margin: 10px 0 10px 5px;
  top: 50%;
  width: 30px;
  border-radius: 50%;
  min-width: inherit;
  padding: 0;
  text-align: center;
}
.project .project-version .circle {
  width: 40px;
  height: 40px;
  background-color: #66daff;
  color: #fff;
  font-size: 18px;
  border-radius: 50%;
  text-align: center;
  line-height: 28px;
  margin: 5px 0;
  border: 6px solid #fff;
}
.project .project-version .circle.active {
  border-color: #a7eaff;
  background: #00aee7;
}
.project .project-version .dropdown-trigger {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50px;
  background-color: transparent;
  border: none;
  border-left: 1px solid #d3e9ea;
  padding: 0;
  opacity: 0.5;
  color: #255378;
  font-size: 12px;
}
.project .project-version .dropdown-trigger i {
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  vertical-align: middle;
}
.project .project-version .dropdown-trigger.active i {
  -ms-transform: rotate(0);
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
  vertical-align: inherit;
}
.project .project-version .dropdown {
  position: absolute;
  top: 100%;
  margin-top: 1px;
  left: -1px;
  right: -1px;
  background: #fff;
  border-top: none;
  -webkit-transition: all 0.35s ease-in;
  -moz-transition: all 0.35s ease-in;
  -ms-transition: all 0.35s ease-in;
  -o-transition: all 0.35s ease-in;
  transition: all 0.35s ease-in;
  opacity: 0;
  background-color: #fff;
  border: 1px solid #d3e9ea;
  border-top: 0;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  z-index: 9999;
  pointer-events: none;
  max-height: 0;
  overflow: hidden;
}
.project .project-version .dropdown li {
  display: block;
  clear: both;
}
.project .project-version .dropdown li .name {
  border-right: 1px solid #d3e9ea;
  color: #255378;
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  opacity: 0.8;
  padding: 8px 20px;
  width: 210px;
  float: left;
}
.project .project-version .dropdown li .version-content {
  float: left;
  min-width: 110px;
  padding: 4px 0;
  position: relative;
}
.project .project-version .dropdown li .version-content .circle {
  position: relative;
  z-index: 1;
  margin: 0;
  font-size: 16px;
  height: 30px;
  width: 30px;
  border: 0;
  line-height: 30px;
  float: right;
  margin-right: 5px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.project .project-version .dropdown li .version-content .circle:hover,
.project .project-version .dropdown li .version-content .circle.active {
  background: #00aee7;
}
.project .project-version .dropdown li .version-content i {
  font-size: 14px;
  color: #7c97ae;
  opacity: 0.6;
  float: right;
  padding: 8px 0;
  margin-right: 6px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.project .project-version .dropdown li .version-content .prev-version {
  position: absolute;
  top: 0;
  right: -20px;
  padding: 4px 49px 4px 4px;
  width: 342px;
  opacity: 0;
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  z-index: 0;
}
.project .project-version .dropdown li .version-content.active .prev-version {
  opacity: 1;
  right: 0;
}
.project .project-version .dropdown li .version-content.active i {
  -ms-transform: rotate(0);
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
}
.project .drawing-content {
  margin: 22px;
  position: relative;
  border: 2px solid #d3e9ea;
  border-radius: 4px;
  max-height: 617px;
  overflow: hidden;
}
.project .drawing-content.fullscreen {
  padding: 0;
  background: #fff;
  bottom: 0;
  height: 100%;
  left: 0;
  margin: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9999999;
  max-height: inherit;
}
.project .drawing-content.fullscreen .drawing-tools {
  top: 20px;
  right: 20px;
}
.project .drawing-content.fullscreen .drawing-img {
  border: 0;
}
.project .drawing-content .drawing-tools {
  position: absolute;
  right: 30px;
  top: 20px;
  width: 60px;
  z-index: 1;
}
.project .drawing-content .drawing-tools .tool {
  width: 60px;
  height: 60px;
  background: rgba(37, 85, 120, 0.4);
  border-radius: 50%;
  padding: 0;
  border: 0;
  margin-bottom: 20px;
  color: #fff;
  text-align: center;
  line-height: 60px;
  -moz-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
}
.project .drawing-content .drawing-tools .tool:hover,
.project .drawing-content .drawing-tools .tool.active {
  background: #255378;
}
.project .drawing-content .drawing-tools .tool.zoom-in,
.project .drawing-content .drawing-tools .tool.zoom-out {
  font-size: 32px;
}
.project .drawing-content .drawing-tools .tool.full-screen {
  font-size: 28px;
}
.project .drawing-content .drawing-tools .tool.info {
  font-size: 27px;
}
.project .drawing-content .drawing-tools .tool.edit {
  font-size: 24px;
}
.project .drawing-content .drawing-tools .tool.lock-panzoom {
  background: #255378;
  font-size: 26px;
  color: #65e373;
}
.project .drawing-content .drawing-tools .tool.lock-panzoom.active {
  color: #ee3636;
}
.project .drawing-content .drawing-tools .tool.lock-panzoom i {
  display: none;
}
.project .drawing-content .drawing-tools .tool.lock-panzoom i.active {
  display: inline;
}
.project .drawing-content .drawing-img {
  text-align: center;
  height: 100%;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .project .drawing-content {
    max-height: 620px;
  }
}
.project .drawing-info {
  border-top: 1px solid #d3e9ea;
  padding: 12px 20px;
}
.project .drawing-info .general-info {
  color: #7c98ae;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  padding: 10px 0;
}
.project .drawing-info .general-info span {
  color: rgba(37, 83, 120, 0.8);
  font-size: 22px;
  line-height: 27px;
  font-weight: 300;
  padding-left: 12px;
}
.project .drawing-info .btn-group {
  float: right;
}
.project .drawing-info .btn-group .btn {
  background-color: #f7f9f9;
  border: 1px solid #d3e9ea;
  border-radius: 6px;
  min-width: 50px;
  padding: 10px 5px;
  color: #1e201d;
  font-size: 24px;
}
.project .drawing-info .btn-group .btn:first-child {
  color: #d85555;
  font-size: 15px;
  background-color: #fff;
  font-weight: 400;
}
.project .drawing-info .btn-group .btn:last-child {
/*  border-bottom-left-radius: 0z;
  border-top-left-radius: 0;*/
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}
.project .drawing-info .btn-group.btn-share {
  margin-left: 15px;
}
.project .drawing-info .btn-group.btn-share .btn {
  border-radius: 6px;
  background-color: #48b13b;
  border: 1px solid #3b9130;
  color: #fff;
  font-size: 22px;
}
.project .drawing-info .issued {
  color: #7c98ae;
  float: left;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  padding: 2px 0;
  margin: 0 10px 0 0;
}
.project .drawing-info .issued .circle {
  width: 43px;
  height: 43px;
  background-color: #00aee7;
  color: #fff;
  font-size: 22px;
  border-radius: 50%;
  text-align: center;
  line-height: 43px;
  margin-left: 5px;
  display: inline-block;
}
.project .drawing-info .issued .circle + .tooltip .tooltip-inner {
  background-color: #456c8c;
  border-radius: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}
.project .drawing-info .issued .circle + .tooltip .tooltip-arrow {
  border-bottom: 5px solid #456c8c;
}
.project .comments .heading {
  background-color: #f9fafb;
  padding: 0 10px 0 15px;
  border-bottom: 1px solid #c4dce0;
  margin-bottom: 1px;
}
.project .comments .heading h2 {
  padding: 16px 0;
}
.project .comments .heading .search {
  float: right;
  width: 100%;
  max-width: 200px;
  position: relative;
}
.project .comments .heading .search:after {
  font-family: 'fontello';
  position: absolute;
  right: 8px;
  top: 10px;
  content: '\e80d';
  color: #97adbf;
  font-size: 12px;
}
.project .comments .heading .search input {
  height: 30px;
  line-height: 30px;
  width: 100%;
  border: 1px solid #d3e9ea;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  margin: 10px 0;
  color: #97adbf;
  font-size: 15px;
  padding: 0 25px 0 10px;
}
.project .comments .heading .search ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #97adbf;
}
.project .comments .heading .search :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #97adbf;
  opacity: 1;
}
.project .comments .heading .search ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #97adbf;
  opacity: 1;
}
.project .comments .heading .search :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #97adbf;
}
.project .comments .comments-list {
  max-height: 320px;
  overflow: auto;
  padding-right: 10px;
}
.project .comments .comments-list .list-group {
  margin: 0;
}
.project .comments .comments-list .list-group .list-group-item {
  padding: 10px;
  border-width: 0 0 1px;
  border-radius: 0;
  margin-bottom: 0;
  border-color: #edf2f3;
}
.project .comments .comments-list .list-group .list-group-item .media .profile {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 5px;
}
.project .comments .comments-list .list-group .list-group-item .media .media-body {
  position: relative;
  padding-right: 40px;
}
.project .comments .comments-list .list-group .list-group-item .media .media-body .button-content {
  border-left: 2px solid #edf2f3;
  padding: 7px 0 7px 8px;
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
}
.project .comments .comments-list .list-group .list-group-item .media .media-body .button-content button {
  border: 0;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  background-color: #00aee7;
  margin-bottom: 10px;
  display: block;
  padding: 0;
  font-size: 10px;
  position: relative;
}
.project .comments .comments-list .list-group .list-group-item .media .media-body .button-content button.draw {
  font-size: 22px;
  background-color: #fff;
}
.project .comments .comments-list .list-group .list-group-item .media .media-body .button-content button.draw i {
  left: -1px;
  position: absolute;
  top: -3px;
  color: #00aee7;
}
.project .comments .comments-list .list-group .list-group-item .media .media-body .button-content button.edit i {
  left: 5px;
  position: absolute;
  top: 3px;
}
.project .comments .comments-list .list-group .list-group-item .media .media-body .button-content button.delete {
  font-size: 9px;
  margin-bottom: 0;
}
.project .comments .comments-list .list-group .list-group-item .media .media-body .button-content button.delete i {
  left: 5px;
  position: absolute;
  top: 4px;
}
.project .comments .comments-list .list-group .list-group-item .media .media-body .media-heading {
  position: relative;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #255378;
  padding-right: 20px;
}
.project .comments .comments-list .list-group .list-group-item .media .media-body p {
  font-size: 15px;
  font-weight: 300;
  line-height: 20px;
  color: #000;
  margin-bottom: 10px;
}
.project .comments .comments-list .list-group .list-group-item .media .media-body .view {
  color: #00aee7;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  float: left;
}
.project .comments .comments-list .list-group .list-group-item .media .media-body .view:hover {
  text-decoration: underline !important;
}
.project .comments .comments-list .list-group .list-group-item .media .media-body .date {
  color: #a9bfd0;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
}
.project .comments .add-comment {
  background-color: #f9fafb;
  padding: 15px;
  overflow: hidden;
  border-top: 1px solid #edf2f3;
}
.project .comments .add-comment .comment-content {
  position: relative;
  overflow: hidden;
}
.project .comments .add-comment .comment-content textarea {
  background-color: #fff;
  border: 1px solid #d3e9ea;
  border-radius: 5px;
  width: 100%;
  height: 100px;
  resize: none;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  padding: 10px 15px;
  color: #c5d2d4;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.project .comments .add-comment .comment-content .send {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 100px;
  border: 0;
  border-left: 1px solid #d3e9ea;
  background: transparent;
}
.project .comments .add-comment .comment-content .send i {
  color: #73c2ff;
  opacity: 0.8;
}
.project .comments .add-comment .comment-content .send:hover i {
  opacity: 1;
}
.project .comments .add-comment .comment-content ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #c5d2d4;
}
.project .comments .add-comment .comment-content :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #c5d2d4;
  opacity: 1;
}
.project .comments .add-comment .comment-content ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #c5d2d4;
  opacity: 1;
}
.project .comments .add-comment .comment-content :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c5d2d4;
}
.project .approved .heading {
  padding: 0 10px 0 15px;
  border-bottom: 1px solid #d3e9ea;
}
.project .approved .heading h2 {
  padding: 16px 0;
}
.project .approved .approved-list .list-group {
  margin: 0;
}
.project .approved .approved-list .list-group .list-group-item {
  border-width: 0;
  border-radius: 0;
  margin-bottom: 0;
  padding: 0 10px;
}
.project .approved .approved-list .list-group .list-group-item .media {
  display: table;
}
.project .approved .approved-list .list-group .list-group-item .media .profile {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 12px 0 0;
  padding: 0;
  position: relative;
}
.project .approved .approved-list .list-group .list-group-item .media .profile .status-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #fff;
  position: absolute;
  bottom: 0;
  right: 0;
}
.project .approved .approved-list .list-group .list-group-item .media .profile .status-icon.red {
  background-color: #EE3636;
}
.project .approved .approved-list .list-group .list-group-item .media .profile .status-icon.green {
  background-color: #15B936;
}
.project .approved .approved-list .list-group .list-group-item .media .profile .status-icon.violet {
  background-color: #543285;
}
.project .approved .approved-list .list-group .list-group-item .media .profile img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.project .approved .approved-list .list-group .list-group-item .media .status {
  border-left: 1px solid #d3e9ea;
  display: table-cell;
  vertical-align: middle;
  padding-left: 10px;
  padding-top: 8px;
}
.project .approved .approved-list .list-group .list-group-item .media .status .icon {
  width: 35px;
  height: 35px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  line-height: 33px;
  font-size: 13px;
  border: 1px solid #999;
}
.project .approved .approved-list .list-group .list-group-item .media .status .icon.check {
  color: #48b13b;
}
.project .approved .approved-list .list-group .list-group-item .media .status .icon.delete {
  color: #EE3636;
}
.project .approved .approved-list .list-group .list-group-item .media .status .icon.question-mark {
  color: #999;
  font-size: 18px;
  font-weight: 400;
}
.project .approved .approved-list .list-group .list-group-item .media .media-body {
  padding: 12px 40px 0 15px;
  position: relative;
  vertical-align: middle;
}
.project .approved .approved-list .list-group .list-group-item .media .media-body .media-heading {
  position: relative;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  color: #255378;
  padding-top: 0;
  margin: 0;
}
.project .approved .approved-list .list-group .list-group-item .media .media-body p {
  color: #98abb4;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}
.project .approved .approved-list .list-group .list-group-item .media .media-body .icon {
  color: #00aee7;
  font-size: 18px;
  position: absolute;
  right: 10px;
  top: 25px;
}
.project .approved .approved-list .list-group .list-group-item:last-child .media .profile {
  margin-bottom: 12px;
}
.project .approved .approved-list .list-group .list-group-item:last-child .media .media-body,
.project .approved .approved-list .list-group .list-group-item:last-child .media .status {
  padding-bottom: 12px;
}
/*.modal, .modal-backdrop {
    position: absolute !important;
}*/
.modal-dialog {
  width: 90%;
  max-width: 480px;
  margin-top: 150px;
}
.modal-dialog .modal-content {
  border-radius: 7px;
  border: 0;
  box-shadow: none;
}
.modal-dialog .modal-content .modal-header {
  border-color: #cbcbcb;
  text-align: center;
}
.modal-dialog .modal-content .modal-header h4 {
  opacity: 0.85;
  color: #255378;
  font-size: 19px;
  font-weight: 400;
  line-height: 23px;
}
.modal-dialog .modal-content .modal-header h4 i {
  color: #00c0ff;
  font-size: 17px;
  margin-right: 4px;
}
.modal-dialog .modal-content .modal-body {
  padding: 20px;
}
.modal-dialog .modal-content .modal-body.add-drawing-form .field-row {
  margin-bottom: 20px;
}
.modal-dialog .modal-content .modal-body.add-drawing-form .field-row:last-child {
  margin: 0;
}
.modal-dialog .modal-content .modal-body.add-drawing-form .field-row .left {
  padding-left: 0;
  padding-right: 10px;
}
.modal-dialog .modal-content .modal-body.add-drawing-form .field-row .right {
  padding-right: 0;
  padding-left: 10px;
}
.modal-dialog .modal-content .modal-body.add-drawing-form .field-row input {
  height: 40px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2) inset;
  color: rgba(37, 83, 120, 0.5);
  font-size: 19px;
}
.modal-dialog .modal-content .modal-body.share-form {
  padding: 40px 40px 20px;
}
.modal-dialog .modal-content .modal-body.share-form .field-row {
  margin-bottom: 20px;
}
.modal-dialog .modal-content .modal-body.share-form .field-row input,
.modal-dialog .modal-content .modal-body.share-form .field-row textarea {
  font-size: 19px;
}
.modal-dialog .modal-content .modal-body.share-form .switch {
  position: relative;
  margin-bottom: 15px;
  padding-right: 40px;
}
.modal-dialog .modal-content .modal-body.share-form .switch span {
  color: #255378;
  font-size: 18px;
  font-weight: 300;
  display: block;
  line-height: 22px;
}
.modal-dialog .modal-content .modal-body.share-form .switch .check {
  position: absolute;
  right: 0;
  top: 4px;
}
.modal-dialog .modal-content .modal-footer {
  border-color: #cbcbcb;
}
.modal-dialog .modal-content .modal-footer .btn {
  margin: 0 8px;
}
/************************* */
/* Settings page  */
/************************* */
.settings {
  max-width: 800px;
  padding: 30px 15px;
}
.settings .settings-content .heading {
  padding: 0 10px 0 15px;
  border-bottom: 1px solid #d3e9ea;
  background-color: #f9fafb;
}
.settings .settings-content .heading h2 {
  float: left;
  color: #7c98ae;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  padding: 16px 0;
}
.settings .settings-content .form {
  border-bottom: 1px solid #d3e9ea;
  padding: 20px;
}
.settings .settings-content .form .input-content {
  width: 55%;
  float: left;
  padding-left: 26px;
}
.settings .settings-content .form .input-content .field-row {
  margin-bottom: 20px;
}
.settings .settings-content .form .input-content .field-row:last-child {
  margin: 0;
}
.settings .settings-content .form .input-content .field-row label {
  margin: 25px 0 25px;
}
.settings .settings-content .form .input-content .field-row input {
  text-align: center;
}
.settings .settings-content .form .upload-content {
  width: 45%;
  float: left;
  position: relative;
  padding: 19px 0 19px 6%;
}
.settings .settings-content .form .upload-content #imgUpload {
  position: relative;
  border-radius: 50%;
  width: 222px;
  height: 222px;
  background: url("../images/upload-photo.png") no-repeat 0 0;
  overflow: hidden;
}
.settings .settings-content .form .upload-content #imgUploadBtn {
  border-radius:50%; /* Ivan */
  padding: 100px 0;
  top: 0px;
  width: 222px;
  position: absolute;
  cursor: pointer;
}
.settings .settings-content .form .upload-content #imgUploadBtn i {
  font-size: 32px;
  color: #b7c6d1;
  display: block;
}
.settings .settings-content .form .upload-content #imgUploadBtn span {
  color: #b7c6d1;
  display: block;
  font-size: 15px;
  line-height: normal;
}
.settings .settings-content .form .upload-content .upload-control {
  position: absolute;
  right: 2px;
  top: 20px;
}
.settings .settings-content .form .upload-content .upload-control button {
  width: 38px;
  height: 38px;
  background-color: #255378;
  border-radius: 50%;
  border: 0;
  display: block;
  margin-bottom: 6px;
  text-align: center;
  font-size: 16px;
}
.settings .settings-content .form .upload-content .upload-control button.close-upload {
  font-size: 14px;
}
.settings .settings-content .form .upload-content .upload-control button i {
  color: #fff;
}
.settings .settings-content .form .info-cta {
  margin-top: 30px;
}
.settings .settings-content .form .info-cta .btn {
  margin: 0 10px;
}
.settings .settings-content .notifications {
  padding: 30px 0;
  border-bottom: 1px solid #d3e9ea;
}
.settings .settings-content .notifications h3 {
  color: #255378;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  padding: 0 20px 0 60px;
}
.settings .settings-content table {
  width: 100%;
  margin-top: 10px;
}
.settings .settings-content table thead th {
  opacity: 0.8;
  color: #255378;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  text-align: center;
  padding: 0 10px 15px;
  width: 140px;
}
.settings .settings-content table thead th:first-child {
  width: auto;
}
.settings .settings-content table thead th:last-child {
  border: 0;
}
.settings .settings-content table tbody td {
  opacity: 0.8;
  color: #255378;
  font-size: 18px;
  line-height: 26px;
  padding: 3px 20px;
  vertical-align: middle;
  min-width: 100px;
}
.settings .settings-content table tbody td strong {
  font-weight: 500;
}
.settings .settings-content table tbody td:first-child {
  border-right: 1px solid #d3e9ea;
}
.settings .settings-content table tbody td i {
  color: #00c0ff;
  font-size: 26px;
  margin-right: 15px;
  float: left;
}
.settings .settings-content table tbody td .switch {
  display: table;
  margin: 0 auto;
}
.settings .settings-content table tbody td .switch label {
  margin: 0;
}
.settings .settings-content table.download-settings {
  margin: 0;
}
.settings .settings-content table.download-settings tbody td {
  opacity: 1;
  padding: 5px 20px;
}
.settings .settings-content table.download-settings tbody td p {
  font-size: 18px;
}
.settings .settings-content .download {
  padding: 28px 0;
  border-bottom: 1px solid #d3e9ea;
}
.settings .settings-content .cta {
  padding: 30px 0;
}
.settings .settings-content .cta .cta-content {
  display: table;
  margin: 0 auto;
}
.settings .settings-content .cta .cta-content .btn {
  border-color: #a6b1ba;
  color: #a6b1ba;
  margin: 0 15px;
  padding: 5px 25px;
}
.settings .settings-content .cta .cta-content .btn:hover,
.settings .settings-content .cta .cta-content .btn:active {
  background-color: #a6b1ba;
  color: #fff;
}
.settings .settings-content .cta .cta-content .btn.delete-account {
  min-width: 200px;
}


.settings #imgUpload div[class*=Wrapper] img {
  max-width:none !important;
}

.settings #imgUpload .imgUpload_imgUploadForm,
.settings #imgUpload .cropControls {
  display: none !important;
}

/************************* */
/* New project page  */
/************************* */
.new-project {
  padding: 20px 5px;
}
.new-project .new-project-content .heading {
  border-bottom: 1px solid #cbcbcb;
  padding: 20px;
}
.new-project .new-project-content .heading h2 {
  color: rgba(37, 83, 120, 0.85);
  font-size: 22px;
  font-weight: 300;
  line-height: 27px;
}
.new-project .new-project-content .heading h2 i {
  color: #00c0ff;
  font-size: 27px;
  margin-right: 5px;
  vertical-align: middle;
}
.new-project .new-project-content .box {
  overflow: inherit;
  height: 100%;
}
.new-project .new-project-content .info-col {
  padding: 0;
  min-height: 620px;
}
.new-project .new-project-content .info-col.left {
  padding-right: 20px;
}
.new-project .new-project-content .info-col:last-child {
  margin-right: 0;
}
.new-project .new-project-content .info-col .info-heading {
  border-bottom: 1px solid #e5e5e5;
  padding: 13px 0 14px;
  text-align: center;
}
.new-project .new-project-content .info-col .info-heading h3 {
  font-size: 20px;
  color: rgba(37, 83, 120, 0.85);
}
.new-project .new-project-content .info-col .info-content {
  min-height: 480px;
  padding: 0 0 15px;
}
.new-project .new-project-content .info-col .info-content h4 {
  color: #9FBBD3;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  padding: 0 20px 15px 20px;
}
.new-project .new-project-content .info-col .info-content .field-row {
  margin-bottom: 15px;
  position: relative;
  padding: 0 20px;
}
.new-project .new-project-content .info-col .info-content .field-row:last-child {
  margin: 0;
}
.new-project .new-project-content .info-col .info-content .search {
  width: 100%;
  position: relative;
  padding: 15px 20px;
}
.new-project .new-project-content .info-col .info-content .search:after {
  font-family: 'fontello';
  content: '\e80d';
  color: #97adbf;
  font-size: 19px;
  position: absolute;
  right: 40px;
  top: 24px;
}
.new-project .new-project-content .info-col .info-content .search input {
  height: 50px;
  width: 100%;
  border: 1px solid #abb4c5;
  border-radius: 5px;
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.1);
  color: rgba(37, 83, 120, 0.5);
  font-size: 20px;
  padding: 0 25px 0 16px;
}
.new-project .new-project-content .info-col .info-content .search input:focus {
  color: #255378;
}
.new-project .new-project-content .info-col .info-content .search ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #97adbf;
}
.new-project .new-project-content .info-col .info-content .search :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #97adbf;
  opacity: 1;
}
.new-project .new-project-content .info-col .info-content .search ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #97adbf;
  opacity: 1;
}
.new-project .new-project-content .info-col .info-content .search :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #97adbf;
}
.new-project .new-project-content .info-col .info-content .category-button {
  padding: 15px 20px;
  border: 2px solid #d3e9ea;
  border-right: 0;
  border-left: 0;
}
.new-project .new-project-content .info-col .info-content .category-button ul {
  overflow: hidden;
}
.new-project .new-project-content .info-col .info-content .category-button ul li {
  float: left;
  width: 50%;
}
.new-project .new-project-content .info-col .info-content .category-button ul li:first-child {
  border-right: 1px solid #dae4e4;
  padding-right: 20px;
}
.new-project .new-project-content .info-col .info-content .category-button ul li:last-child {
  padding-left: 20px;
}
.new-project .new-project-content .info-col .info-content .category-button ul li .btn {
  border-radius: 5px;
  width: 100%;
  padding: 6px 15px;
  background-color: #e4eef0;
  border: 1px solid rgba(151, 151, 151, 0.4);
  color: #6d92af;
  min-width: inherit;
}
.new-project .new-project-content .info-col .info-content .category-button ul li .btn:hover,
.new-project .new-project-content .info-col .info-content .category-button ul li .btn.active {
  background-color: #6d92af;
  border-color: #6d92af;
  color: #fff;
  box-shadow: none;
}
.new-project .new-project-content .info-col .info-content .add-new-project {
  padding: 15px 20px 0;
}
.new-project .new-project-content .info-col .info-content .btn-full-width {
  border-radius: 8px;
  width: 100%;
  background-color: transparent;
  color: #ff9600;
  border-color: #ff9600;
  padding: 9px 15px;
}
.new-project .new-project-content .info-col .info-content .btn-full-width i {
  padding-right: 5px;
}
.new-project .new-project-content .info-col .info-content .btn-full-width:hover {
  background-color: #ff9600;
  color: #fff;
}
.new-project .new-project-content .info-col .info-content .projects-category {
  margin-top: 15px;
  display: none;
}
.new-project .new-project-content .info-col .info-content .projects-category.active {
  display: block;
}
.new-project .new-project-content .info-col .info-content .custom-input .field-row input {
  color: #255378;
}
.new-project .new-project-content .info-col .info-content .custom-input .field-row.not-active input {
  background-color: #E4EEF0;
  box-shadow: none;
  border: 1px solid rgba(151, 151, 151, 0.4);
}
.new-project .new-project-content .info-col .info-content .custom-input .field-row.active input {
  background-color: #6D92AF;
  border-color: #6D92AF;
  box-shadow: none;
  color: #fff;
}
.new-project .new-project-content .info-col .info-content .custom-input .field-row.active ::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #fff;
}
.new-project .new-project-content .info-col .info-content .custom-input .field-row.active :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
  opacity: 1;
}
.new-project .new-project-content .info-col .info-content .custom-input .field-row.active ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
  opacity: 1;
}
.new-project .new-project-content .info-col .info-content .custom-input .field-row.active :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}
.new-project .new-project-content .info-col .info-content .custom-input .field-row.active .btn.right-arrow {
  color: #fff;
}
.new-project .new-project-content .info-col .info-content .custom-input .field-row .btn {
  border-radius: 50%;
  min-width: inherit;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  padding: 0;
  border-color:transparent;

}
.new-project .new-project-content .info-col .info-content .custom-input .field-row .btn.add {
  line-height: 28px;
  height: 30px;
  width: 30px;
  font-size: 15px;
  top: 50%;
  margin-top: -15px;
}
.new-project .new-project-content .info-col .info-content .custom-input .field-row .btn.delete {
  height: 20px;
  width: 20px;
  background: transparent;
  color: #d03232;
  right: 4px;
  top: 8px;
}
.new-project .new-project-content .info-col .info-content .custom-input .field-row .btn.delete:hover,
.new-project .new-project-content .info-col .info-content .custom-input .field-row .btn.delete:focus {
  border-color: transparent;
  box-shadow: none;
}
.new-project .new-project-content .info-col .info-content .custom-input .field-row .btn.right-arrow {
  background: transparent;
  color: #979797;
  font-size: 16px;
  height: 100%;
  padding: 0 15px;
  right: 26px;
  top: 0;
}
.new-project .new-project-content .info-col .info-content .custom-input .field-row .btn.right-arrow:hover,
.new-project .new-project-content .info-col .info-content .custom-input .field-row .btn.right-arrow:focus {
  border-color: transparent;
  box-shadow: none;
}
.new-project .new-project-content .info-col .info-content .custom-input .field-row.two-input .number {
  float: left;
  width: 23%;
}
.new-project .new-project-content .info-col .info-content .custom-input .field-row.two-input .name {
  float: right;
  width: 72%;
}
.new-project .new-project-content .info-col .info-content .custom-input.padding-right .field-row {
  padding-right: 45px;
}
.new-project .new-project-content .info-col .info-content .nav-tabs {
  border-bottom: 2px solid #d3e9ea;
}
.new-project .new-project-content .info-col .info-content .nav-tabs li {
  width: 33.33%;
  text-align: center;
}
.new-project .new-project-content .info-col .info-content .nav-tabs li a {
  color: rgba(37, 83, 120, 0.85);
  font-size: 20px;
  border: 0;
  border-bottom: 6px solid transparent;
  border-radius: 0;
  margin: 0 0 -1px 0;
  padding: 9px 15px 6px;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.new-project .new-project-content .info-col .info-content .nav-tabs li.active a,
.new-project .new-project-content .info-col .info-content .nav-tabs li:hover a {
  border-color: #466c8d;
  background: transparent;
}
.new-project .new-project-content .info-col .info-content .tab-content .field-row {
  padding: 0;
}
.new-project .new-project-content .info-col .info-content .tab-content .info-tab {
  padding: 20px;
}
.new-project .new-project-content .info-col .info-content .tab-content .info-tab .field-row {
  margin-bottom: 20px;
  max-width: 440px;
}
.new-project .new-project-content .info-col .info-content .tab-content .info-tab .btn {
  max-width: 440px;
}
.new-project .new-project-content .info-col .info-content .tab-content .structure-tab .info-heading {
  padding: 11px 0 14px;
}
.new-project .new-project-content .info-col .info-content .tab-content .structure-tab .info-heading h3 {
  border-right: 1px solid #d3e9ea;
  padding: 2px 0;
  color: rgba(37, 83, 120, 0.5);
}
.new-project .new-project-content .info-col .info-content .tab-content .structure-tab .info-heading h3.border0 {
  border: 0;
}
.new-project .new-project-content .info-col .info-content .tab-content .structure-tab .structure-tab-content {
  padding: 20px;
}
.new-project .new-project-content .info-col .info-content .tab-content .structure-tab .structure-tab-content.border-right {
  border-right: 1px solid #d3e9ea;
}
.new-project .new-project-content .info-col .info-content .tab-content .structure-tab .structure-tab-content .btn-full-width {
  max-width: 455px;
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .search {
  max-width: 400px;
  float: right;
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content {
  padding: 0 20px;
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table {
  width: 100%;
  border-bottom: 2px solid #d3e9ea;
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr {
  border-top: 2px solid #d3e9ea;
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td {
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  padding: 12px 10px;
  position: relative;
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td:first-child {
  padding-right: 0;
  width: 55px;
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td:first-child img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td:nth-child(2) {
  color: #255378;
  width: 220px;
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td:nth-child(2):after {
  border: 1px solid #d3e9ea;
  content: "";
  height: 34px;
  margin-top: -17px;
  position: absolute;
  right: 0;
  top: 50%;
  width: 1px;
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td:nth-child(3) {
  color: #98abb4;
  padding-left: 18px;
  width: 240px;
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td:last-child {
  padding-right: 0;
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td .custom-select {
  position: relative;
  display: inline-block;
  cursor: default;
  line-height: 40px;
  clear: both;
  color: rgba(37, 83, 120, 0.5);
  width: 100%;
  height: 40px;
  border: 1px solid #979797;
  border-radius: 5px;
  float: left;
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td .custom-select .selected {
  width: 100%;
  height: 40px;
  overflow: hidden;
  padding: 2px 10px;
  cursor: pointer;
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td .custom-select .selected .status-icon {
  width: 22px;
  height: 22px;
  border: 3px solid transparent;
  margin: 6px 8px 6px 0;
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td .custom-select .selectArrow {
  cursor: pointer;
  width: 40px;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 8px;
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td .custom-select .selectArrow.active {
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  -webkit-transform: rotate(0);
  transform: rotate(0);
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td .custom-select .selectOptions {
  position: absolute;
  top: 36px;
  left: -1px;
  right: -1px;
  overflow: hidden;
  background: #fff;
  display: none;
  z-index: 10;
  border: 1px solid #d3e9ea;
  border-radius: 0 0 5px 5px;
  box-shadow: 1px 5px 8px rgba(0, 0, 0, 0.12);
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td .custom-select .title-option {
  background-color: #f5f5f5;
  padding: 5px 10px;
  overflow: hidden;
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td .custom-select .title-option span {
  display: inline-block;
  float: left;
  color: #255378;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td .custom-select .title-option ul {
  float: right;
  line-height: normal;
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td .custom-select .title-option ul li {
  float: left;
  font-size: 12px;
  font-weight: 700;
  margin-left: 10px;
  color: rgba(37, 83, 120, 0.5);
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td .custom-select .selectOption {
  cursor: pointer;
  width: 100%;
  padding: 10px;
  overflow: hidden;
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td .custom-select .selectOption:hover {
  background-color: #F9F9F9;
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td .custom-select .status-icon {
  width: 35px;
  height: 35px;
  border: 4px solid transparent;
  border-radius: 50%;
  display: inline-block;
  margin-right: 12px;
  float: left;
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td .custom-select .status-icon.unassigned {
  border-color: #92a9bb;
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td .custom-select .status-icon.blue {
  border-color: #4950ff;
  background-color: #4950ff;
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td .custom-select .status-icon.red {
  border-color: #c82d2d;
  background-color: #c82d2d;
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td .custom-select .status-icon.green {
  border-color: #16b935;
  background-color: #16b935;
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td .custom-select .status-icon.violet {
  border-color: #553285;
  background-color: #553285;
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td .custom-select .status-icon.gray {
  border-color: #d4dae5;
  background-color: #d4dae5;
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td .custom-select .title {
  color: #255378;
  font-size: 20px;
  font-weight: 300;
  display: inline-block;
  float: left;
  line-height: 34px;
}
.new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td .custom-select .title.unassigned {
  color: rgba(37, 83, 120, 0.5);
}
@media screen and (max-width: 1600px) {
  /************************* */
  /* New project page  */
  /************************* */
  .new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    position: relative;
    display: block;
    padding: 6px 0 6px 30% !important;
    text-align: left;
    white-space: normal;
  }
  .new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td:before {
    content: attr(data-title);
    font-size: 16px;
    left: 0;
    padding-right: 10px;
    position: absolute;
    text-align: left;
    white-space: nowrap;
    width: 25%;
    color: #255378;
    top: 50%;
    margin-top: -13px;
  }
  .new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td:first-child {
    padding-right: 0;
    width: auto;
  }
  .new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td:first-child img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 12px;
  }
  .new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td:nth-child(2) {
    color: #255378;
    width: auto;
  }
  .new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td:nth-child(2):after {
    display: none;
  }
  .new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td:nth-child(3) {
    color: #98abb4;
    width: auto;
  }
  .new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td:last-child {
    padding-right: 0;
  }
  .project .comments .heading {
    padding: 0 15px 15px;
  }
  .project .comments .heading h2 {
    padding: 16px 0 0;
  }
  .project .comments .heading .search {
    max-width: inherit;
    margin-top: 8px;
  }
  .project .comments .heading .search:after {
    top: 6px;
  }
  .project .comments .heading .search input {
    margin: 5px 0;
  }
}
@media screen and (max-width: 1500px) {
  /************************* */
  /* Project page  */
  /************************* */
  .project .comments .comments-list .list-group .list-group-item .media .profile {
    display: none;
  }
  .project .comments .comments-list .list-group .list-group-item .media .media-body {
    display: block;
    padding-left: 5px;
    width: 100%;
    min-height: 95px;
  }
}
@media screen and (max-width: 1366px) {
  /************************* */
  /* Index page  */
  /************************* */
  .plans-list .col-md-3 {
    width: 50%;
  }
  .plans-list .col-md-3 .plans-list-content {
    margin-bottom: 20px;
  }
  /************************* */
  /* Settings page  */
  /************************* */
  .settings .settings-content .form .upload-content {
    width: 45%;
    float: left;
    position: relative;
    padding-left: 4%;
  }
  /************************* */
  /* People page  */
  /************************* */
  .people-list .col-md-3 {
    width: 50%;
  }
  /************************* */
  /* New project page  */
  /************************* */
  .new-project .new-project-content .info-col {
    display: block;
    width: 100%;
  }
  .new-project .new-project-content .info-col.left {
    padding: 0;
  }
  /************************* */
  /* Project page  */
  /************************* */
  .project .left-side.active {
    width: 100%;
  }
  .project .right-side {
    padding-left: 0;
  }
  .project .right-side.active {
    width: 100%;
    padding-left: 15px;
  }
  .project .right-side.active .comments .comments-list .list-group .list-group-item .media .profile {
    display: inline-block;
  }
  .project .right-side.active .comments .comments-list .list-group .list-group-item .media .media-body {
    display: inherit;
    padding-left: 0;
    width: auto;
  }
  .project .drawing-content .drawing-tools .tool {
    margin-bottom: 10px;
  }
  .project .comments .comments-list .list-group .list-group-item .media .profile {
    display: none;
  }
  .project .comments .comments-list .list-group .list-group-item .media .media-body {
    display: block;
    padding-left: 5px;
    width: 100%;
    min-height: 95px;
  }
  .project .approved .approved-list .list-group .list-group-item .media .profile {
    display: none;
  }
  .project .approved .approved-list .list-group .list-group-item .media .media-body {
    padding-left: 5px;
  }
  .project .approved .approved-list .list-group .list-group-item .media .media-body .icon {
    top: 13px;
  }
}
@media screen and (max-width: 991px) {
  .left-side-nav-open {
    height: 100%;
    overflow: hidden;
    position: fixed;
    width: 100%;
  }
  .page-content {
    margin-left: 0;
  }
  /************************* */
  /* Index page  */
  /************************* */
  .plans-list .plans-list-content {
    margin-bottom: 20px;
  }
  /************************* */
  /* Header and left side nav  */
  /************************* */
  #header {
    /*.left-top-nav {
            width: 240px;
            position: fixed;
            height: 100%;
            background-color: #172e42;
            left: -240px;
            -moz-transition: all 200ms linear 0s;
            -o-transition: all 200ms linear 0s;
            -webkit-transition: all 200ms linear 0s;
            transition: all 200ms linear 0s;

            &.open {
                left: 0;
            }

            li {
                border-bottom: 1px solid #2c4357;
                width: 100%;
                margin: 0;
                padding: 0;

                a {
                    color: #fff;
                    display: block;
                    padding: 0 30px;

                    span {
                        opacity: 1;
                    }
                }
            }
        }*/
  }
  #header .logo {
    float: none;
    margin: 0 auto;
    box-shadow: none;
    background-image: none;
  }
  #header .logo a {
    color: #255378;
  }
  #header .nav-toggle {
    left: 0;
    position: absolute;
    top: 0;
  }
  #header .right-top-nav {
    position: absolute;
    right: 0;
    top: 0;
  }
  .left-side-nav {
    left: -240px;
    padding-top: 70px;
  }
  .left-side-nav .side-nav-title {
    display: none;
  }
  .left-side-nav.push {
    left: 0;
  }
  /************************* */
  /* Project page  */
  /************************* */
  .project .comments .heading {
    padding: 0 10px 0 15px;
  }
  .project .comments .heading h2 {
    padding: 16px 0;
  }
  .project .comments .heading .search {
    max-width: 200px;
    margin-top: 0;
  }
  .project .comments .heading .search:after {
    top: 10px;
  }
  .project .comments .heading .search input {
    margin: 10px 0;
  }
  .project .comments .comments-list .list-group .list-group-item .media .profile {
    display: inline-block;
  }
  .project .comments .comments-list .list-group .list-group-item .media .media-body {
    display: inherit;
    padding-left: 0;
    width: auto;
  }
}
@media screen and (max-width: 767px) {
  .text-right-xs {
    text-align: right;
  }
  /************************* */
  /* Login page  */
  /************************* */
  .login .login-content h1 {
    padding: 40px 0;
    font-size: 40px;
  }
  .login .login-content .login-form {
    max-width: 90%;
    padding-bottom: 30px;
  }
  .login .login-content .login-form h2 {
    font-size: 20px;
    padding: 20px 0;
    margin-bottom: 35px;
  }
  .login .login-content.register .login-form h2 {
    font-size:15px !important;
  }

  .login .login-content .login-form .field-row {
    padding: 0 20px;
  }
  .login .login-content .login-form .field-row input {
    height: 50px;
    font-size: 20px;
  }
  .login .login-content .login-form .field-row .check-content label {
    font-size: 14px;
  }
  .login .login-content .login-form .field-row .forgotten-pass {
    font-size: 14px;
  }
  .login .login-content:not(.register) .login-form .btn {
    margin: 60px auto 0;
  }

  .login .login-content.register .login-form .btn {
    margin: 30px auto 0;
  }
  /************************* */
  /* Index page  */
  /************************* */
  .plans-list .col-md-3 {
    width: 100%;
  }
  .plans-list .plans-list-content {
    margin-bottom: 30px;
  }
  /************************* */
  /* Header and left side nav  */
  /************************* */
  #header .nav-toggle {
    display: block;
    left: 0;
    padding: 19px 0;
  }
  #header .nav-toggle li {
    padding: 0 15px;
  }
  #header .nav-toggle li:first-child {
    padding: 4px 15px 0 20px;
  }
  #header .nav-toggle li:first-child:after {
    display: none;
  }
  #header .nav-toggle li .menu-trigger {
    height: 20px;
    width: 26px;
  }
  #header .nav-toggle li .menu-trigger span:nth-child(2),
  #header .nav-toggle li .menu-trigger span:nth-child(3) {
    top: 9px;
  }
  #header .nav-toggle li .menu-trigger span:nth-child(4) {
    top: 18px;
  }
  #header .nav-toggle li .sub-menu-trigger {
    font-size: 24px;
  }
  #header .right-top-nav li .account a img {
    height: 35px;
    width: 35px;
  }
  #header .right-top-nav li .dropdown-notifications {
    position: fixed;
    left: 20px;
    width: auto;
  }
  .left-side-nav {
    left: -240px;
  }
  .left-side-nav.open {
    left: 0;
  }
  /************************* */
  /* People page  */
  /************************* */
  .people-list {
    padding: 20px 10px;
  }
  .people-list .col-md-3 {
    width: 100%;
  }
  .people-list .people-list-content {
    margin: 0 0 20px;
  }
  .people-list .people-list-content .heading .control a {
    margin-left: 10px;
  }
  /************************* */
  /* Settings page  */
  /************************* */
  .settings {
    padding: 20px 5px;
  }
  .settings .settings-content .form .input-content {
    padding: 0;
    width: 100%;
  }
  .settings .settings-content .form .input-content input {
    max-width: 400px;
    margin: 0 auto;
  }
  .settings .settings-content .form .upload-content {
    display: table;
    float: none;
    margin: 0 auto;
    padding-left: 0;
    width: 180px;
  }
  .settings .settings-content .form .upload-content #imgUpload {
    width: 180px;
    height: 180px;
    background-size: cover;
  }
  .settings .settings-content .form .upload-content #imgUploadBtn { /*here*/
    padding: 80px 0;
    top: 0px;
    width: 180px;
    position: absolute;
  }
  .settings .settings-content .form .upload-content .upload-control {
    position: absolute;
    right: -38px;
    top: 26px;
  }
  .settings .settings-content .form .upload-content .upload-control button {
    height: 30px;
    width: 30px;
    font-size: 14px;
  }
  .settings .settings-content .form .info-cta {
    margin-top: 20px;
  }
  .settings .settings-content .form .info-cta .btn {
    margin: 0 10px 15px;
  }
  .settings .settings-content .notifications h3 {
    padding: 0 20px;
  }
  .settings .settings-content table tbody td {
    min-width: inherit;
  }
  .settings .settings-content .cta {
    padding: 30px 0 15px;
  }
  .settings .settings-content .cta .cta-content .btn {
    display: block;
    margin: 0 auto 15px;
    width: 152px;
  }
  .settings .settings-content .cta .cta-content .btn.delete-account {
    width: 200px;
  }
  /************************* */
  /* Project page  */
  /************************* */
  .project .comments .heading .search {
    max-width: 180px;
  }
  .project .project-version .title {
    font-size: 14px;
    line-height: 16px;
    padding: 9px 20px;
    width: 115px;
    min-width: inherit;
  }
  .project .project-version .version-list {
    margin-left: 10px;
  }
  .project .project-version .version-list li {
    margin-right: 0;
  }
  .project .project-version .version-list li .add {
    font-size: 12px;
    height: 28px;
    line-height: 26px;
    margin: 10px 0 10px 5px;
    top: 50%;
    width: 28px;
    border-radius: 50%;
    min-width: inherit;
    padding: 0;
    text-align: center;
  }
  .project .project-version .circle {
    border-width: 3px;
    font-size: 12px;
    font-weight: 400;
    height: 30px;
    line-height: 25px;
    margin: 9px 0;
    width: 30px;
  }
  .project .project-version .dropdown-trigger {
    width: 40px;
    font-size: 10px;
  }
  .project .project-version .dropdown li .name {
    border-right: 1px solid #d3e9ea;
    color: #255378;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    opacity: 0.8;
    padding: 5px 20px;
    width: 115px;
    float: left;
  }
  .project .project-version .dropdown li .version-content {
    float: left;
    min-width: 72px;
    padding: 4px 0;
    position: relative;
  }
  .project .project-version .dropdown li .version-content .circle {
    font-size: 12px;
    font-weight: 400;
    height: 24px;
    line-height: 24px;
    margin-right: 5px;
    width: 24px;
  }
  .project .project-version .dropdown li .version-content i {
    font-size: 10px;
    padding: 8px 0;
    margin-right: 6px;
  }
  .project .project-version .dropdown li .version-content .prev-version {
    position: absolute;
    top: 0;
    right: -20px;
    padding: 4px 49px 4px 4px;
    width: 342px;
    opacity: 0;
    z-index: 0;
  }
  .project .drawing-content .drawing-tools {
    right: 3px;
    top: 0;
    width: 24px;
  }
  .project .drawing-content .drawing-tools .tool {
    height: 28px;
    line-height: 28px;
    margin-bottom: 8px;
    margin-left: 4px;
    width: 28px;
  }
  .project .drawing-content .drawing-tools .tool.zoom-in,
  .project .drawing-content .drawing-tools .tool.zoom-out,
  .project .drawing-content .drawing-tools .tool.full-screen,
  .project .drawing-content .drawing-tools .tool.edit,
  .project .drawing-content .drawing-tools .tool.lock-panzoom {
    font-size: 12px;
  }
  .project .drawing-content .drawing-tools .tool.info {
    font-size: 14px;
  }
  .project .drawing-info {
    padding: 4px 20px 5px;
  }
  .project .drawing-info .general-info {
    font-size: 12px;
    padding: 4px 0;
    display: block;
  }
  .project .drawing-info .general-info span {
    padding-left: 8px;
    font-size: 18px;
  }
  .project .drawing-info .btn-content {
    display: table;
    margin: 0 auto;
  }
  .project .drawing-info .btn-content .btn-group {
    float: right;
  }
  .project .drawing-info .btn-content .btn-group .btn {
    min-width: 40px;
    padding: 4px 5px;
    font-size: 18px;
  }
  .project .drawing-info .btn-content .btn-group .btn:first-child {
    font-size: 12px;
  }
  .project .drawing-info .btn-content .btn-group.btn-share .btn {
    font-size: 19px;
  }
  .project .drawing-info .issued {
    float: right;
    margin: 0;
    padding: 2px 0;
  }
  .project .drawing-info .issued span {
    display: none;
  }
  .project .drawing-info .issued .circle {
    width: 28px;
    height: 28px;
    font-size: 20px;
    line-height: 28px;
    margin-left: 0;
  }
  .project .drawing-info .issued .circle + .tooltip .tooltip-inner {
    font-size: 12px;
    font-weight: 300;
    line-height: 22px;
    width: 75px;
    padding: 2px;
  }
  .project .drawing-info .issued .circle + .tooltip .tooltip-arrow {
    border-bottom: 5px solid #456c8c;
  }
  .modal-dialog {
    margin: 150px auto 30px;
  }
  .modal-dialog .modal-content .modal-body.share-form {
    padding: 40px 25px 25px;
  }
  .modal-dialog .modal-content .modal-body.share-form .field-row input {
    height: 44px;
  }
  .modal-dialog .modal-content .modal-body.share-form .switch span {
    font-size: 16px;
    padding: 2px 0;
  }
  .modal-dialog .modal-content .modal-footer .btn {
    margin: 10px auto;
    display: table;
  }
  /************************* */
  /* New project page  */
  /************************* */
  .new-project .new-project-content .info-col {
    min-height: inherit;
  }
  .new-project .new-project-content .info-col .info-content {
    min-height: inherit;
  }
  .new-project .new-project-content .info-col .info-content .search input {
    height: 40px;
    padding: 0 5px;
    font-size: 16px;
  }
  .new-project .new-project-content .info-col .info-content .search:after {
    font-size: 16px;
    right: 30px;
    top: 20px;
  }
  .new-project .new-project-content .info-col .info-content .custom-input .field-row input {
    color: #255378;
    font-size: 16px;
    padding: 0 5px;
    height: 40px;
  }
  .new-project .new-project-content .info-col .info-content .custom-input .field-row .btn.delete {
    right: 0;
  }
  .new-project .new-project-content .info-col .info-content .custom-input .field-row .btn.right-arrow {
    font-size: 12px;
    right: 12px;
  }
  .new-project .new-project-content .info-col .info-content .custom-input .field-row.two-input .number {
    float: left;
    width: 23%;
  }
  .new-project .new-project-content .info-col .info-content .custom-input .field-row.two-input .name {
    float: right;
    width: 72%;
  }
  .new-project .new-project-content .info-col .info-content .custom-input.padding-right .field-row {
    padding-right: 28px;
  }
  .new-project .new-project-content .info-col .info-content .nav-tabs {
    border-bottom: 2px solid #d3e9ea;
  }
  .new-project .new-project-content .info-col .info-content .nav-tabs li a {
    font-size: 16px;
    border-bottom: 4px solid transparent;
    padding: 9px 5px 6px;
  }
  .new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td {
    font-size: 16px;
    padding: 2px 0 !important;
  }
  .new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td:before {
    display: none;
  }
  .new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td:first-child {
    padding-top: 10px !important;
  }
  .new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td:last-child {
    padding-bottom: 10px !important;
  }
  .new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td .custom-select .selected .status-icon {
    border-width: 2px;
    height: 15px;
    margin: 9px 5px 6px 0;
    width: 15px;
  }
  .new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td .custom-select .selected .title {
    font-size: 16px;
    line-height: 34px;
  }
  .new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td .custom-select .selectArrow {
    width: 30px;
  }
  .new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td .custom-select .title-option {
    background-color: #f5f5f5;
    padding: 5px 10px;
    overflow: hidden;
  }
  .new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td .custom-select .title-option span {
    display: inline-block;
    float: left;
    color: #255378;
    font-size: 12px;
    font-weight: 700;
    line-height: normal;
  }
  .new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td .custom-select .title-option ul {
    display: block;
    float: left;
    margin-top: 4px;
    width: 100%;
  }
  .new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td .custom-select .title-option ul li {
    font-size: 10px;
    font-weight: 700;
    margin-left: 0;
    margin-right: 10px;
  }
  .new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td .custom-select .status-icon {
    border-width: 3px;
    height: 18px;
    margin-right: 8px;
    width: 18px;
  }
  .new-project .new-project-content .info-col .info-content .tab-content .people-tab .table-content table tr td .custom-select .title {
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
  }
}
@media screen and (max-width: 400px) {
  /************************* */
  /* Project page  */
  /************************* */
  .project .right-side .comments .comments-list .list-group .list-group-item .media .profile {
    display: none !important;
  }
  .project .right-side .comments .comments-list .list-group .list-group-item .media .media-body {
    display: block;
    padding-left: 5px !important;
    width: 100%;
    min-height: 95px;
  }
}



.cl{
  position:absolute;
  top:10px;
  left:50%;
  height:48px;
  margin-left:-56px;
}

.cl2{
margin-left:-24px;
}


/*pen css*/

@media screen and (min-width: 700px) {

  .project .drawing-content .drawing-tools {
    width:40px;
  }

  .project .drawing-content .drawing-tools .tool.pen {
      height: 40px;
      line-height: 40px;
      margin-bottom: 12px;
      width: 40px;
  }   /* CSS stuff */
}


.loginlogo{
  display:block;
  margin:0 auto;
  width:251px;
  padding: 110px 0 110px;
}

img.logoimg{
  width:130px;
}

.nobo{
  border-color:transparent;
}

.remove-input{
  border:none;
  padding:0;
  margin:0;
}

@media screen and (max-height: 360px) {

  .pen-bottom.drawing-tools {
    transform-origin: 35% 75%;
    transform: rotate(-90deg);
    bottom:0px !important;
  }

  .pen-bottom.drawing-tools button {
    transform: rotate(90deg);
  }

}

.project .drawing-content .drawing-tools .tool.active:not(.back):not(.save):not(.exit) {
  color:#ff9600;
}


.pen-bottom.drawing-tools {
  top: auto !important;
  bottom: 20px !important;
}

.editMode {
  overflow: hidden;
}

.save-modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  opacity:0.5;
  background-color: #000;
}

.text-input-cont {
    border-radius: 25px;
    padding: 5px;
    background: rgba(37, 85, 120, 0.4);
    position: absolute;
    z-index: 10;
}

.text-input-cont:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -15px;
    width: 0;
    height: 0;
    border-top: solid 15px rgba(37, 85, 120, 0.4);
    border-left: solid 15px transparent;
    border-right: solid 15px transparent;
}

.text-input {
    border: 0;
    border-radius: 25px;
    border-color: #ffffff;
    outline: none;
    -webkit-appearance: none;
    padding: 5px;
    text-align:center;
    font-family: 'idealsans';
    width: 180px;

}

#saveDrawing textarea {
  background-color: #fff;
  border: 1px solid #d3e9ea;
  border-radius: 5px;
  width: 100%;
  height: 100px;
  resize: none;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  padding: 10px 15px;
  color: #c5d2d4;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

#saveDrawing .comment-content {
  padding: 30px 30px 20px 30px;
}

.drawing-content div.marker  {
  cursor: url('/images/Pen.svg') 0 30, auto !important;
}
.drawing-content div.eraser {
  cursor: url('/images/Erase.svg') 0 30, auto !important;
}
.drawing-content div.text {
  cursor: url('/images/Text_pointer.svg') 0 5, auto !important;
}
.drawing-content div.free-view {
  cursor: url('/images/Move.svg') 0 30, auto !important;
}
.drawing-content div.square {
  cursor: url('/images/Square_pointer.svg') , auto !important;
}
.drawing-content div.circle {
  cursor: url('/images/Circle_pointer.svg') , auto !important;
}
.drawing-content {
  touch-action: none;
}
