@charset "UTF-8";

@font-face {
  font-family: "dd-icon";
  src:url("../fonts/dd-icon.eot");
  src:url("../fonts/dd-icon.eot?#iefix") format("embedded-opentype"),
    url("../fonts/dd-icon.woff") format("woff"),
    url("../fonts/dd-icon.ttf") format("truetype"),
    url("../fonts/dd-icon.svg#dd-icon") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "dd-icon" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="dd-icon-"]:before,
[class*=" dd-icon-"]:before {
  font-family: "dd-icon" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dd-w .dd-icon-right:before {
  content: "\62";
}
.dd-w .dd-icon-left:before {
  content: "\63";
}
.dd-w .dd-icon-close:before {
  content: "\61";
}
.dd-w .dd-icon-check:before {
  content: "\65";
}
.dd-w .dd-icon-sign:before {
  content: "\64";
}




.dd-w,.dd-w * {
	box-sizing:border-box;
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.dd-w .dd-trans{
	-webkit-transition: all 0.4s cubic-bezier(.7, 0, .175, 1);
	-moz-transition: all 0.4s cubic-bezier(.7, 0, .175, 1);
	-ms-transition: all 0.4s cubic-bezier(.7, 0, .175, 1);
	transition: all 0.4s cubic-bezier(.7, 0, .175, 1);
}
.dd-w .dd-b {
	-webkit-transition: all 0.6s cubic-bezier(.175,.885,.32,1.275) 0s;
    -moz-transition: all 0.6s cubic-bezier(.175,.885,.32,1.275) 0s;
    -ms-transition: all 0.6s cubic-bezier(.175,.885,.32,1.275) 0s;
    transition: all 0.6s cubic-bezier(.175,.885,.32,1.275) 0s;
}

.dd-w {
	position:absolute;
	width:100%;
	height:100%;
	top:0;
	left:0;
	font-family: sans-serif;
	line-height:0;
	font-size:16px;
	font-weight:bold;
	display:none;
	z-index:9999;
}

.dd-w .dd-w-c,.dd-w .dd-ul li,.dd-s-b-ul ul {
	width:124px;
}
.dd-w ul {
	margin:0;
	padding:0;
	list-style:none;
}
.dd-w li {
	float:left;
	cursor:pointer;
}
.dd-w .dd-c .dd-ul ul {
	overflow:hidden;
}
.dd-w .dd-c .dd-m ul,
.dd-w .dd-c .dd-y ul,
.dd-w .dd-c .dd-s  {
	height:46px;
}
.dd-w .dd-c .dd-m,
.dd-w .dd-c .dd-y,
.dd-w .dd-c .dd-sub-y,
.dd-w .dd-c .dd-s,
.dd-w .dd-c .dd-y .dd-n,
.dd-w .dd-c .dd-sub-y .dd-n,
.dd-w .dd-c .dd-m .dd-n{
	line-height:46px;
	height:46px;
}
.dd-w .dd-y-section {
	height:46px;
	overflow:hidden;
	position:relative;
}
.dd-w .dd-c .dd-m .dd-ul,
.dd-w .dd-c .dd-y .dd-ul,
.dd-w .dd-c .dd-sub-y .dd-ul {
	height:66px;
}
.dd-w .dd-c .dd-d,
.dd-w .dd-c .dd-d ul {
	height:76px;
	line-height:1;
}
.dd-w .dd-c .dd-d .dd-n {
	height:76px;
	line-height:76px;
}
.dd-w .dd-c .dd-d .dd-ul {
	height:96px;
}
.dd-w .dd-c .dd-d {
	border-top: 1px solid rgba(0,0,0,0.05);
	border-bottom: 1px solid rgba(0,0,0,0.05);
}
.dd-w .dd-c .dd-d ul li {
	padding-top: 6px;
}
.dd-w .dd-c .dd-d strong {
	font-size:42px;
}
.dd-w .dd-c .dd-d span {
    font-size: 14px;
}
.dd-w .dd-c .dd-m {
	font-size:20px;
}
.dd-w .dd-o {
	position:fixed;
	width:100%;
	height:100%;
	top:0;
	left:0;
}
.dd-w .dd-c {
    position: absolute;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}
.dd-w .dd-c:after {
    position: absolute;
    content: "";
    left: 50%;
    width: 16px;
    height: 16px;
    margin-left: -8px;
    
}
.dd-w.dd-top .dd-c:after{
	bottom: -8px;
	transform: rotate(-135deg);
	-webkit-transform: rotate(-135deg);
	-moz-transform: rotate(-135deg);
}
.dd-w.dd-bottom .dd-c:after{
	top: -8px;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
}
.dd-w-c {
	overflow: hidden;
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	text-align:center;
	position:relative;
}
.dd-w .dd-c .dd-s {
	cursor:pointer;
}


.dd-w .dd-b {
	position:relative;
	overflow:hidden;
	z-index:1;
}

.dd-w .dd-b:hover {
	transform:scale(1.12);
}
.dd-w .dd-n {
    position: absolute;
    top: 2px;
    font-size: 14px;
    color: #08C;
    display: none;
    cursor: pointer;
    text-align: center;
    width: 40px;
}
.dd-w .dd-n:hover {
	opacity:.8;
}
.dd-w .dd-b:hover .dd-n {
	display:block;
}
.dd-w .dd-n-left {
	left:0;
}
.dd-w .dd-n-right {
	right:0;
}
.dd-w .dd-ul {
	overflow-x:scroll;
}
.dd-w .dd-sub-y {
    z-index: 6;
    position: absolute;
    bottom: 0;
    width: 100%;
	display:none;
}

.dd-w .dd-s-b {
	opacity:0;
	visibility:hidden;
	transform:scale(0);
	position: absolute;
    top: 0;
    left: -1px;
    right: -32px;
    bottom: 46px;
    overflow-x: hidden;
    z-index: 2;
}
.dd-w .dd-s-b-s,
.dd-w .dd-s-b-sub-y {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    line-height: 46px;
    z-index: 2;
	display:none;
	cursor:pointer;
}
.dd-w .dd-s-b-sub-y i {
    display: inline-block;
    margin: 0 6px;
    font-size: 12px;
}
.dd-w .dd-s-b-sub-y span {
    display: inline-block;
    margin: 0 12px;
}

.dd-w .dd-s-b.dd-show {
	opacity:1;
	visibility:visible;
	transform:scale(1);
}
.dd-w .dd-s-b-ul ul {
    padding: 8px;
}
.dd-w .dd-s-b-ul li {
    width: 50%;
    font-size: 16px;
    border-radius: 0;
    margin: 0;
    border-bottom: 0;
    position: relative;
    padding: 14px 0;
    line-height: 1;
    border-bottom: 1px solid rgba(0,0,0,0.05);
    border-radius: 4px;
}
.dd-w .dd-s-b span {
    display: block;
    line-height: 1;
    font-size: 10px;
    text-transform: uppercase;
}

.dd-w .dd-s-b-ul li.dd-on:after {
    position: absolute;
    content: "\64";
    font-family: "dd-icon" !important;
    top: 50%;
    left: 50%;
    margin-left: -20px;
    margin-top: -20px;
    width: 40px;
    height: 40px;
    font-size: 42px;
    font-weight: normal;
}
.dd-w .dd-s-b-d li,.dd-s-b-m li {
    font-size: 18px;
}
.dd-w .dd-clear {
	clear:both; float:none;
}


/* effects */

@-webkit-keyframes dd-bounce {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }

  20% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }

  30% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }

  60% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }

  70% {
    -webkit-transform: scale3d(.95, 1.05, 1);
            transform: scale3d(.95, 1.05, 1);
  }

  80% {
    -webkit-transform: scale3d(1.05, .95, 1);
            transform: scale3d(1.05, .95, 1);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}

@keyframes dd-bounce {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }

  20% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }

  30% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }

  60% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }

  70% {
    -webkit-transform: scale3d(.95, 1.05, 1);
            transform: scale3d(.95, 1.05, 1);
  }

  80% {
    -webkit-transform: scale3d(1.05, .95, 1);
            transform: scale3d(1.05, .95, 1);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}

.dd-w .dd-bounce {
	-webkit-animation-name: dd-bounce;
	animation-name: dd-bounce;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
}

@-webkit-keyframes dd-fadein {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes dd-fadein {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.dd-w .dd-fadein {
	-webkit-animation-name: dd-fadein;
	animation-name: dd-fadein;
	-webkit-animation-duration: .3s;
	animation-duration: .3s;
}

@-webkit-keyframes dd-fadeout {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes dd-fadeout {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

.dd-w .dd-fadeout {
	-webkit-animation-name: dd-fadeout;
	animation-name: dd-fadeout;
	-webkit-animation-duration: .3s;
	animation-duration: .3s;
}


@-webkit-keyframes dd-dropdown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -30%, 0);
            transform: translate3d(0, -30%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

@keyframes dd-dropdown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -30%, 0);
            transform: translate3d(0, -30%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

.dd-w .dd-dropdown {
	-webkit-animation-name: dd-dropdown;
	animation-name: dd-dropdown;
	-webkit-animation-duration: .5s;
	animation-duration: .5s;
}

@-webkit-keyframes dd-alert {
  0%, 100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
  }
}

@keyframes dd-alert {
   0%, 100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translate3d(-10px, 0, 0);
            transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
  }
}

.dd-w .dd-alert {
  -webkit-animation-name: dd-alert;
  animation-name: dd-alert;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
		  
}

.dd-w .dd-sub-y {
	-webkit-transform-origin: bottom;
	transform-origin: bottom;
}


/* DEFAULT STYLE */ 

.dd-w-c {
    color:#333;
}
.dd-w-c {
    border: 1px solid #0088CC;
	box-shadow: 0 0 10px 0 rgba(0, 136, 204, 0.45);
}
.dd-w-c,
.dd-s-b,
.dd-w.dd-bottom .dd-c:after {
	background: #FFFFFF;
}
.dd-w.dd-top .dd-c:after{
	background: #E3F2FA;
}
.dd-c:after {
    background: #FFFFFF;
    border-left: 1px solid #08C;
	border-top: 1px solid #08C;
}
.dd-n,
.dd-sun {
	color: #08C;
}
.dd-c .dd-s,
.dd-s-b-s,
.dd-s-b-sub-y,
.dd-sub-y {
	background: #E3F2FA;
    color: #0088CC;
}
.dd-s-b-ul li.dd-on {
    color: #0088CC;
}
