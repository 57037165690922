/* Generated by Font Squirrel (https://www.fontsquirrel.com) on May 3, 2016 */



@font-face {
    font-family: 'helveticaneue';
    src: url('../fonts/helvetica_neu_bold-webfont.eot');
    src: url('../fonts/helvetica_neu_bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/helvetica_neu_bold-webfont.woff2') format('woff2'),
         url('../fonts/helvetica_neu_bold-webfont.woff') format('woff'),
         url('../fonts/helvetica_neu_bold-webfont.ttf') format('truetype'),
         url('../fonts/helvetica_neu_bold-webfont.svg#helveticaneuebold') format('svg');
    font-weight: 700;
    font-style: normal;

}




@font-face {
    font-family: 'helveticaneue';
    src: url('../fonts/helveticaneue_italic-webfont.eot');
    src: url('../fonts/helveticaneue_italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/helveticaneue_italic-webfont.woff2') format('woff2'),
         url('../fonts/helveticaneue_italic-webfont.woff') format('woff'),
         url('../fonts/helveticaneue_italic-webfont.ttf') format('truetype'),
         url('../fonts/helveticaneue_italic-webfont.svg#helveticaneueitalic') format('svg');
    font-weight: normal;
    font-style: italic;

}




@font-face {
    font-family: 'helveticaneue';
    src: url('../fonts/helveticaneue_light-webfont.eot');
    src: url('../fonts/helveticaneue_light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/helveticaneue_light-webfont.woff2') format('woff2'),
         url('../fonts/helveticaneue_light-webfont.woff') format('woff'),
         url('../fonts/helveticaneue_light-webfont.ttf') format('truetype'),
         url('../fonts/helveticaneue_light-webfont.svg#helveticaneuelight') format('svg');
    font-weight: 300;
    font-style: normal;

}




@font-face {
    font-family: 'helveticaneue';
    src: url('../fonts/helveticaneue_medium-webfont.eot');
    src: url('../fonts/helveticaneue_medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/helveticaneue_medium-webfont.woff2') format('woff2'),
         url('../fonts/helveticaneue_medium-webfont.woff') format('woff'),
         url('../fonts/helveticaneue_medium-webfont.ttf') format('truetype'),
         url('../fonts/helveticaneue_medium-webfont.svg#helveticaneuemedium') format('svg');
    font-weight: 400;
    font-style: normal;

}

@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello.eot?9406906');
  src: url('../fonts/fontello.eot?9406906#iefix') format('embedded-opentype'),
       url('../fonts/fontello.woff2?9406906') format('woff2'),
       url('../fonts/fontello.woff?9406906') format('woff'),
       url('../fonts/fontello.ttf?9406906') format('truetype'),
       url('../fonts/fontello.svg?9406906#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}